<template>
  <section>
    <div class="content-header">
      <h2>What’s it really like out there?</h2>
    </div>
    <div class="content-wrapper">
      <p>Did you know that each corporate job posting attracts an average of 250 resumes?</p>
      <p>Or that the average amount of time it takes to find a job is 24 weeks?</p>
      <p>This <a href="https://zety.com/blog/hr-statistics" target="_blank">list of human resource statistics and research</a>  provides data on resumes, job interviews, and hiring and recruiting. While the website is American, many of the insights apply to Canada.</p>
      <p>This information can help you normalize the average job search process for student jobseekers. Awareness of the realities of the job search can help students understand the importance of persisting in their own job search.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
